import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { SketchPicker } from 'react-color';
import ColorBlock, { NewColorBlock } from './ColorBlock';

interface ColorSchemePickerProps {
    className?: string;
    value: string[];
    onChange: (colors: string[]) => void;
}

export default function ColorSchemePicker(props: ColorSchemePickerProps) {
    const [activeColorIndex, setActiveColorIndex] = useState<number | null>(null);

    const addNewColor = useCallback((color: string) => {
        const newValue = [...props.value, color];

        props.onChange(newValue);
        setActiveColorIndex(newValue.length - 1);
    }, [props.value, props.onChange]);

    const updateActiveColor = useCallback((color: { hex: string }) => {
        if (activeColorIndex === null) {
            return;
        }   

        const newValue = [
            ...props.value.slice(0, activeColorIndex),
            color.hex,
            ...props.value.slice(activeColorIndex + 1),
        ];

        props.onChange(newValue);
    }, [props.value, props.onChange, activeColorIndex]);

    const removeColor = useCallback((index: number) => {
        const newValue = [
            ...props.value.slice(0, index),
            ...props.value.slice(index + 1),
        ];

        props.onChange(newValue);
    }, [props.onChange, props.value]);

    useEffect(() => {
        const handler = () => setActiveColorIndex(null);

        window.addEventListener('click', handler);

        return () => {
            window.removeEventListener('click', handler);
        }
    }, []);

    const activeColor = activeColorIndex !== null ? props.value[activeColorIndex] : activeColorIndex;
    const classNames = cx('ColorSchemePicker', props.className);

    return (
        <div className={classNames} onClick={(event) => event.nativeEvent.stopImmediatePropagation()}>
            <div className="ColorSchemePicker-palette">
                {props.value.map((color, index) => (
                    <ColorBlock
                        key={index}
                        index={index}
                        className="ColorSchemePicker-colorBlock"
                        isActive={activeColorIndex === index}
                        color={color}
                        onClick={() => {
                            if (activeColorIndex === index) {
                                setActiveColorIndex(null);
                            } else {
                                // bypass stopPropagation on parent, and close any other colorpicker open by triggering a click on body
                                document.body.click();
                                setActiveColorIndex(index);
                            }
                        }}
                        onRemove={props.value.length ? (index) => {
                            setActiveColorIndex(null);
                            removeColor(index);
                        }: undefined}
                    />
                ))}
                <NewColorBlock onClick={addNewColor} />
            </div>
            <div className="ColorSchemePicker-colorPicker">
                {activeColor && <SketchPicker color={activeColor} onChange={updateActiveColor} />}
            </div>
        </div>
    );
}