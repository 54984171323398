import React from 'react';
import {SVGSpace, SVGForm, Bound, Pt } from 'pts';
import { saveAs } from 'file-saver';

export interface PtsProps {
    name: string;
    background: string;
    resize?: boolean;
    retina?: boolean;
    play?: boolean;
    touch?: boolean;
    style?: React.CSSProperties;
    canvasStyle: {[key: string]: any};
}

export default abstract class PtsCanvas<ExtraProps = {}> extends React.PureComponent<PtsProps & ExtraProps> {

    static defaultProps = {
        // background: "#9ab",
        resize: true,
        retina: true,
        play: false,
        touch: true,
        style: {},
        canvasStyle: {}
    };
    
    protected canvRef = React.createRef<SVGSVGElement>();
    protected space: SVGSpace = {} as SVGSpace;
    protected form: SVGForm = {} as SVGForm;
    private _touch: boolean = false;

    private init() {
        this.space = new SVGSpace( this.canvRef.current as SVGSVGElement ).setup({
            bgcolor: this.props.background, 
            resize: this.props.resize, 
            // retina: this.props.retina
        });

        this.form = this.space.getForm();
        this.space.add( this );
    }

    private _update() {
        if (this._touch !== this.props.touch) {
            this._touch = Boolean(this.props.touch);
            this.space.bindMouse( this._touch ).bindTouch( this._touch );
        }
    }

    componentDidMount() {
        this.init();
        this._update();
        this.afterMount();
    }

    componentDidUpdate(prevProps: PtsProps & ExtraProps) {
        if (prevProps.background !== this.props.background) {
            this.space.background = this.props.background;
        }

        this.afterUpdate(prevProps);
        this._update();
    }

    saveAsSvg(name: string) {
        saveAs(this.svg(), `${name}.svg`);
    }

    saveAsPng(name: string) {
        this.png().then((png) => png && saveAs(png, `${name}.png`));
    }

    svg() {
        const data = (new XMLSerializer()).serializeToString(this.space.element);

        return new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
    }

    png(scale: number = 4) {
        return new Promise<Blob | null>((done) => {
            const img = new Image();
            const canvas = document.createElement('canvas');
            const DOMURL = window.URL || window.webkitURL || window;

            canvas.width = this.space.element.clientWidth * scale;
            canvas.height = this.space.element.clientHeight * scale;

            const ctx = canvas.getContext('2d');

            ctx && (ctx.fillStyle = this.props.background);
            ctx && ctx.fillRect(0, 0, canvas.width, canvas.height);

            const url = DOMURL.createObjectURL(this.svg());

            img.onload = function () {
                ctx && ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                DOMURL.revokeObjectURL(url);

                canvas.toBlob(done, 'image/png');
            };

            img.src = url;
        });
    }

    playOnce() {
        this.space.playOnce(1);
    }


    // Required: Override this to use Pts' player `animate` callback
    // See guide: https://ptsjs.org/guide/space-0500
    abstract animate(time?: number, ftime?: number): void;
        // this.form.point( this.space.pointer, 20, "circle" );
    // }
    
    // Optional: Override this to use Pts' player `start` callback
    // abstract start( bound, space ): void;
    start(bound: Bound, space: SVGSpace) {}

    afterMount() {}

    afterUpdate(prevProps: PtsProps & ExtraProps) {}


    // Optional: Override this to use Pts' player `resize` callback
    // abstract resize( size, evt ): void;
    resize(size: any, evt: any) {}


    // Optional: Override this to use Pts' player `action` callback
    // abstract action ( type, px, py, evt ): void;
    action (type: string, px: number, py: number, evt: any) {}

    render() {
        return (
            <div className={this.props.name} style={this.props.style}>
                <svg 
                    className={`${this.props.name}-canvas`}
                    style={ this.props.canvasStyle }
                    ref={this.canvRef} 
                />
            </div>
        );
    }
}