import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { SketchPicker } from 'react-color';
import ColorBlock, { NewColorBlock } from './ColorBlock';

interface ColorPickerProps {
    className?: string;
    value: string;
    onChange: (color: string) => void;
}

export default function ColorPicker(props: ColorPickerProps) {
    const [isActive, setActive] = useState<boolean>(false);
    const onChangeColor = useCallback((color: { hex: string }) => {
        props.onChange && props.onChange(color.hex);
    }, []);

    useEffect(() => {
        const handler = () => setActive(false);

        window.addEventListener('click', handler);

        return () => {
            window.removeEventListener('click', handler);
        }
    }, []);

    const classNames = cx('ColorPicker', props.className);

    return (
        <div className={classNames} onClick={(event) => {
            event.nativeEvent.stopImmediatePropagation();
        }}>
            <ColorBlock
                index={-1}
                color={props.value}
                isActive={isActive}
                onClick={() => {
                    // bypass stopPropagation on parent, and close any other colorpicker open by triggering a click on body
                    document.body.click();
                    setActive((isActive) => !isActive)
                }}
            />
            {isActive && (
                <div className="ColorPicker-picker">
                    <SketchPicker color={props.value} onChange={onChangeColor} />
                </div>)
            }
        </div>
    );
}