import PtsCanvas from '../../components/PtsCanvas';
import { Group, Pt, Create, Curve, Util, Bound } from 'pts';

export interface EditorState {
    shapeColors: string[];
    bgColor: string;
    shapePointsRange: number[];
    shapeCount: number;
    offsetCanvas: number;
    shapeRadiusRange: number[];
    shapeDeformation: number;
}

export interface EditorCanvasProps {
    value: EditorState;
}

export default class EditorCanvas extends PtsCanvas<EditorCanvasProps> {

    static defaultProps = {
        ...PtsCanvas.defaultProps,
        name: 'editor-canvas',
    }

    shapePoints: Group = null as any as Group;
    shapeColors: string[] = null as any as string[];

    afterMount() {
        // FIXME: hack because render on first pain is not working
        setTimeout(() => {
            this.playOnce();
        }, 200);
    }

    afterUpdate(prevProps: EditorCanvasProps) {
        const shapeCountChanged = this.props.value.shapeCount != prevProps.value.shapeCount;
        const offsetCanvasChanged = this.props.value.offsetCanvas != prevProps.value.offsetCanvas;
        const shapeColorsChanged = this.props.value.shapeColors != prevProps.value.shapeColors;

        if (shapeCountChanged || offsetCanvasChanged) {
            this.shapePoints = null as any as Group;
        }

        if (shapeCountChanged || shapeColorsChanged || offsetCanvasChanged) {
            this.shapeColors = null as any as string[];
        }

        if (this.props.value !== prevProps.value) {
            this.playOnce();
        }
    }

    renderArt() {
        this.form.scope( this );
        this.space.clear();

        const { shapeColors, offsetCanvas, shapePointsRange, shapeCount, shapeRadiusRange, shapeDeformation } = this.props.value;
        const bounds = Bound.fromGroup([
            new Pt([-offsetCanvas, -offsetCanvas]), 
            new Pt([this.space.width + (offsetCanvas * 2), this.space.height + (offsetCanvas * 2)])
        ]);

        if (!this.shapePoints) {
            this.shapePoints = Create.distributeRandom(bounds, this.props.value.shapeCount);
        }

        if (!this.shapeColors) {
            this.shapeColors = this.shapePoints.map(() => shapeColors[Util.randomInt(shapeColors.length)]);
        }

        this.shapePoints.forEach((center, index) => {
            const radius = Util.randomInt(shapeRadiusRange[1], shapeRadiusRange[0]);
            const shapeColor = this.shapeColors[index];
            const shapePointsCount = Util.randomInt(
                shapePointsRange[1],
                shapePointsRange[0]
            );

            const shapePoints = Create.radialPts(center, radius, shapePointsCount);

            shapePoints.forEach((p) => {
                p.add(
                    Math.round(radius * 0.1 * Util.randomInt(
                        shapeDeformation, 
                        -shapeDeformation
                    ))
                );
            });

            // close path
            shapePoints.insert(shapePoints.slice(0, 3), shapePoints.length);
            
            const shapeSpline = Curve.bspline( shapePoints, 10 );

            this.form.fillOnly(shapeColor).line(shapeSpline);
        });
    }

    animate() {
        this.renderArt();
    }

    resize() {
        if (this.form.ready) {
            this.shapePoints = null as any as Group;
            this.playOnce();
        }
    }
}
