import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { FormGroup, RangeSlider, Slider, Checkbox, Button, ButtonGroup, Divider } from '@blueprintjs/core';
import debounce from 'lodash/debounce';
import {
    useQueryParams,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
    DelimitedNumericArrayParam,
    ArrayParam,
    BooleanParam
} from 'use-query-params';
import ColorSchemePicker from '../../components/ColorSchemePicker';
import ColorPicker from '../../components/ColorPicker';
import EditorCanvas, { EditorState } from './EditorCanvas';

const DEFAULT_EDITOR_STATE = {
    shapeColors: ['#2B90FF', '#FF5D74', '#F8EA60'],
    bgColor: '#FFFFFF',
    shapePointsRange: [3, 9],
    shapeCount: 50,
    offsetCanvas: 70,
    shapeRadiusRange: [10, 150],
    shapeDeformation: 10,
};

const EDITOR_STATE_QUERY = {
    shapeColors: DelimitedArrayParam,
    bgColor: StringParam,
    shapePointsRange: DelimitedNumericArrayParam,
    shapeCount: NumberParam,
    offsetCanvas: NumberParam,
    shapeRadiusRange: DelimitedNumericArrayParam,
    shapeDeformation: NumberParam,
};

export default function Editor() {
    const editor = useRef<EditorCanvas>(null);
    const [editorQueryState, setEditorQueryState] = useQueryParams(EDITOR_STATE_QUERY);
    const [editorState, setEditorState] = useState<EditorState>(
        Object.entries(editorQueryState).reduce((acc, [key, value]) => {
            if (typeof value !== 'undefined') {
                // @ts-ignore
                acc[key] = value;
            }

            return acc;
        }, {...DEFAULT_EDITOR_STATE})
    );

    const [editorDebouncedState, setEditorDebouncedState] = useState<EditorState>(editorState);

    const updateFieldDebounced = useCallback(debounce((editorState: Partial<EditorState>) => {
        setEditorDebouncedState((prev) => ({
            ...prev,
            ...editorState,
        }));
    }, 150), []);

    useMemo(() => {
        setEditorQueryState({
            ...editorDebouncedState,
        });
    }, [editorDebouncedState])

    const updateField = useCallback((fieldName: keyof EditorState) => {
        return (newValue: EditorState[keyof EditorState]) => {
            setEditorState((prev) => ({
                ...prev,
                [fieldName]: newValue,
            }));
            updateFieldDebounced({
                [fieldName]: newValue,
            });
        };
    }, []);

    const current = editorState;
    const debounced = editorDebouncedState;

    return (
        <div className="Editor">
            <div className="Editor-sidebar">
                <div className="Editor-controls">
                    <FormGroup
                        label="Shape's Color Palette"
                    >
                        <ColorSchemePicker
                            value={current.shapeColors}
                            onChange={updateField('shapeColors')}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Background Color"
                    >
                        <ColorPicker value={current.bgColor} onChange={updateField('bgColor')} />
                    </FormGroup>
                    <FormGroup
                        // helperText="Helper text with details..."
                        label="Offset Canvas (px)"
                    >
                        <Slider value={current.offsetCanvas} min={0} max={600} labelStepSize={200} onChange={updateField('offsetCanvas')} />
                    </FormGroup>
                    <FormGroup
                        label="Number of Shapes"
                    >
                        <Slider value={current.shapeCount} min={1} max={600} labelStepSize={100} onChange={updateField('shapeCount')} />
                    </FormGroup>
                    <FormGroup
                        label="Shape Radius"
                    >
                        <RangeSlider value={current.shapeRadiusRange as [number, number]} min={1} max={600} labelStepSize={100} onChange={updateField('shapeRadiusRange')} />
                    </FormGroup>
                    <FormGroup
                        label="Shape Points"
                    >
                        <RangeSlider value={current.shapePointsRange as [number, number]} min={3} max={30} labelStepSize={13} onChange={updateField('shapePointsRange')} />
                    </FormGroup>
                    <FormGroup
                        label="Shape Deformation"
                    >
                        <Slider value={current.shapeDeformation} min={0} max={100} labelStepSize={15} onChange={updateField('shapeDeformation')} />
                    </FormGroup>
                    <FormGroup>
                        <Button
                            onClick={() => {
                                editor && editor.current && editor.current.playOnce();
                            }}
                        >
                            Randomize
                        </Button>
                    </FormGroup>
                </div>
                <div className="Editor-controlsBottom">
                    <ButtonGroup fill className="Editor-controlsDownload">
                        <Button
                            onClick={() => {
                                editor && editor.current && editor.current.saveAsSvg(`repppeat-${new Date().getTime()}`)
                            }}
                        >
                            Download SVG
                        </Button>
                        <Button
                            onClick={() => {
                                editor && editor.current && editor.current.saveAsPng(`repppeat-${new Date().getTime()}`)
                            }}
                        >
                            Download PNG
                        </Button> 
                    </ButtonGroup>
                </div>
            </div>
            <div className="Editor-canvas">
                <EditorCanvas
                    ref={editor}
                    value={debounced}
                    background={debounced.bgColor}
                />
            </div>
        </div>
    );
}