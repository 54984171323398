import React, { useCallback } from 'react';
import cx from 'classnames';

const DEFAULT_COLOR = '#FFFFFF';

interface ColorBlockProps {
    color: string;
    index: number;
    className?: string;
    isNew?: boolean;
    onClick?: (color: string) => void;
    onRemove?: (index: number, color: string) => void;
    isActive?: boolean;
}

export default function ColorBlock(props: ColorBlockProps) {
    const onClick = useCallback(() => {
        props.onClick && props.onClick(props.color);
    }, [props.onClick, props.color]);

    const onRemove = useCallback((event) => {
        event.stopPropagation();
        
        props.onRemove && props.onRemove(props.index, props.color);
    }, [props.onRemove, props.index, props.color]);

    const styles = { backgroundColor: props.color };
    const classNames = cx('ColorBlock', props.className, {
        'ColorBlock--new': props.isNew,
        'is-active': props.isActive,
    });

    return (
        <span className={classNames} style={styles} onClick={onClick}>
            {props.onRemove && <span className="ColorBlock-remove" title="remove" onClick={onRemove}></span>}
        </span>
    );
}

ColorBlock.defaultProps = {
    color: DEFAULT_COLOR,
}

export function NewColorBlock(props: Omit<ColorBlockProps, 'isNew' | 'color' | 'index'>) {
    return <ColorBlock {...props} index={-1} isNew />;
}
